
// -----------------------------------------------------------------------------
// Search box show/hide
// -----------------------------------------------------------------------------

const searchButton = document.querySelector('.jsSearchButton');
const searchForm = document.querySelector('.jsSearchForm');
const searchClose = searchForm.querySelector('.jsSearchCloseButton');
const searchInput = searchForm.querySelector('.jsSearchInput');

if (searchForm) {
  searchButton.addEventListener('click', function() {
    searchForm.classList.add('jsSearchShow');
    searchInput.focus();
    console.log(searchInput);
  })
  searchClose.addEventListener('click', function() {
    searchForm.classList.remove('jsSearchShow');
  })
}


// -----------------------------------------------------------------------------
// Baguette box
// -----------------------------------------------------------------------------

baguetteBox.run('.gallery');

// -----------------------------------------------------------------------------
// Sticky header
// -----------------------------------------------------------------------------

const myElement = document.querySelector('.jsStickyHeader');
// construct an instance of Headroom, passing the element
const headroom = new Headroom(myElement);
// initialise
headroom.init();


// -----------------------------------------------------------------------------
// Mobile nav toggler
// -----------------------------------------------------------------------------

const navOpener = document.querySelector('.jsMobileNavButton');
const mobileNav = document.querySelector('.jsMobileNav');
const body = document.querySelector('body');

navOpener.addEventListener('click', () => {
  mobileNav.classList.toggle('jsShowMenu');
  navOpener.classList.toggle('jsClose');
  body.classList.toggle('jsMobileNavOpen');
});

// -----------------------------------------------------------------------------
// Limit characters
// -----------------------------------------------------------------------------

const comments = document.getElementById('comments');

if (comments) {

  const commentBox = document.getElementById('comment');
  const commentSubmit = document.getElementById('submit');
  const commentLabel = document.querySelector('.comment-form-comment label')
  const message = document.createElement('span');
  message.classList.add('js-char-count');
  commentLabel.appendChild(message);

  commentBox.addEventListener('input', event => {
    const target = event.currentTarget;
    const maxLength = 1500;
    const currentValue = target.value;
    const currentLength = currentValue.length;
    message.innerText = `${maxLength - currentLength} characters left`;

    if (currentLength >= maxLength) {
      target.value = target.value.substr(0, maxLength);
      commentSubmit.disabled = true;
      commentBox.classList.add('js-char-count-over');
    } else {
      commentSubmit.disabled = false;
      commentBox.classList.remove('js-char-count-over');

    }
});

}

// -----------------------------------------------------------------------------
// Add note to author
// -----------------------------------------------------------------------------

const author = document.querySelector('.comment-form-author label');

if (author) {
  const node = document.createElement('span');
  const textnode = document.createTextNode(' e.g. Jenny C, Sevenoaks School');
  node.appendChild(textnode);
  author.appendChild(node);
}

// -----------------------------------------------------------------------------
// Select box
// -----------------------------------------------------------------------------

const jumpList = document.getElementById('jsProjectJumplist');

if (jumpList) {
  jumpList.addEventListener('change', function () {
  location.assign(this.value);
});
}


// -----------------------------------------------------------------------------
// Home slider
// -----------------------------------------------------------------------------

const jsHomeSlider = document.querySelector('.jsHomeSlider');

// if slider and is Homer than tablet
if (jsHomeSlider && window.screen.width > 767) {
  tns({
    container: jsHomeSlider,
    speed: 750,
    mode: 'gallery',
    autoplay: true,
    autoplayButtonOutput: false,
    controls: false,
    lazyload: true,
    nav: false,
    mouseDrag: true,
  });
} else if (jsHomeSlider && window.screen.width < 767) {
  // show small images
  tns({
    container: jsHomeSlider,
    speed: 750,
    mode: 'gallery',
    autoplay: true,
    autoplayButtonOutput: false,
    controls: false,
    lazyload: true,
    nav: false,
    mouseDrag: true,
    controlsPosition: 'bottom'
  });
  const lazyImages = jsHomeSlider.querySelectorAll('.tns-lazy-img');

  lazyImages.forEach(img => {
    img.classList.remove('tns-lazy-img');
    img.src = img.dataset.srcSml;
  });
}

// -----------------------------------------------------------------------------
// Home tweets slider
// -----------------------------------------------------------------------------

const jsTweetsSlider = document.querySelector('.jsTweetsSlider');


// if slider and is Homer than tablet
if (jsTweetsSlider && window.screen.width > 767) {
  tns({
    autoplay: true,
    autoplayButtonOutput: false,
    container: jsTweetsSlider,
    controls: false,
    lazyload: true,
    mouseDrag: true,
    nav: false,
    gutter: 30,
    speed: 750,
        responsive: {
          760: {
            items: 2,
            center: false,
          },
          980: {
            items: 3,
          },
          1400: {
            items: 4,
          },
          1600: {
            items: 5,
          },
        },
  })
} else if (jsTweetsSlider && window.screen.width < 767) {
  // show small images
  tns({
    autoplay: false,
    autoplayButtonOutput: false,
    center: true,
    container: jsTweetsSlider,
    controls: false,
    lazyload: true,
    mode: "gallery",
    mouseDrag: true,
    nav: false,
    speed: 750,
  })
  // const lazyImages = jsTweetsSlider.querySelectorAll(".tns-lazy-img")

  // lazyImages.forEach((img) => {
  //   img.classList.remove("tns-lazy-img")
  //   img.src = img.dataset.src
  // })
}
//  if (jsTweetsSlider ) {
//   tns({
//     autoplay: true,
//     autoplayButtonOutput: false,
//     container: jsTweetsSlider,
//     controls: false,
//     items: 1,
//     gutter: 20,
//     lazyload: true,
//     mouseDrag: true,
//     nav: false,
//     speed: 750,
//     responsive: {
//       760: {
//         items: 2,
//         center: false,
//       },
//       980: {
//         items: 3,
//       },
//       1400: {
//         items: 4,
//       },
//       1600: {
//         items: 5,
//       },
//     },
//   })
//   // const lazyImages = jsTweetsSlider.querySelectorAll('.tns-lazy-img');

//   // lazyImages.forEach(img => {
//   //   img.classList.remove('tns-lazy-img');
//   // });
// }

// -----------------------------------------------------------------------------
// Large slider
// -----------------------------------------------------------------------------

const jsSliderLarge = document.querySelector('.jsSliderLarge');

// if slider and is larger than tablet
// if (jsSliderLarge && window.screen.width > 767) {
if (jsSliderLarge) {
tns({
  container: jsSliderLarge,
  items: 1,
  autoplay: false,
  autoplayButtonOutput: false,
  autoplayTimeout: 10000,
  nav: false,
  mouseDrag: true,
  center: false,
  navPosition: 'bottom',
  controlsPosition: 'bottom',
  prevButton: '.jsSliderLargePrev',
  nextButton: '.jsSliderLargeNext',
  responsive: {
    640: {
      items: 2,
      center: false,
    },
    980: {
      items: 3
    },
    1400: {
      items: 4
    },
    1600: {
      items: 5
    }
  }
});
}
// else if (jsSliderLarge && window.screen.width < 767) {
//   // show small images

//   const lazyImages = jsSliderLarge.querySelectorAll('.tns-lazy-img');

//   lazyImages.forEach(img => {
//     img.classList.remove('tns-lazy-img');
//     img.src = img.dataset.srcSml;
//   });
// }


// -----------------------------------------------------------------------------
// Tabs
// -----------------------------------------------------------------------------

const tabsContainer = document.querySelector('.jsTabs');
if (tabsContainer) {
  const tabs = Array.from(tabsContainer.querySelectorAll('.jsTab'));
  const tabsContent = Array.from(tabsContainer.querySelectorAll('.jsTabContent'));

  tabs.forEach(tab => {
    tab.addEventListener('click', function () {
      const currentTab = this.dataset.tab;

      // Change aria selected state tabs
      tabs.forEach(tab => {
        tab.setAttribute('aria-selected', false);
      });

      this.setAttribute('aria-selected', true);


      // Change aria selected state content
      tabsContent.forEach(tabsContent => {
        tabsContent.setAttribute('aria-selected', false);
      });
      const currentContent = document.getElementById(`tab-content-${currentTab}`);

      currentContent.setAttribute('aria-selected', true);

    })
  });
}


// -----------------------------------------------------------------------------
// Newsletter popup
// -----------------------------------------------------------------------------

const newsletterPref = localStorage.getItem('newsletterPopupShown')

if (!newsletterPref) {
  const newsletterPopup = document.getElementById('newsletter-popup')

  setTimeout(() => {
    newsletterPopup.showModal()

  }, 10000);

  // Close event
  newsletterPopup.addEventListener("close", (e) => {
    console.log('closed!');
    localStorage.setItem('newsletterPopupShown', 'closed')
  })
}



