
// -----------------------------------------------------------------------------
// Map pin animation
// -----------------------------------------------------------------------------


if (document.querySelector('.jsProjectsMapContainer')) {

  document.addEventListener("DOMContentLoaded", function (event) {



  // -----------------------------------------------------------------------------
  // Map stuff
  // -----------------------------------------------------------------------------




  mapboxgl.accessToken = 'pk.eyJ1IjoiZmVsaXh0aGVoYXQiLCJhIjoiMG11YTg2QSJ9.oVizn_klUJw7bxTh5QJmgA';
  var projectsMap = new mapboxgl.Map({
    container: 'projectsMap',
    style: 'mapbox://styles/felixthehat/cjwwc9e0c16ap1co6ue3g5gf3',
    center: [-1.0, 8.0],
    zoom: 0,
    // minZoom: 3,
    pitch: 0,
    // bearing: 100,
    // attributionControl: false
  });

  projectsMap.scrollZoom.disable();
  // Add zoom and rotation controls to the map.

  var nav = new mapboxgl.NavigationControl();
  projectsMap.addControl(nav, 'bottom-right');

  // // using flyTo options
  projectsMap.flyTo({
    center: [-1.0, 8.0],
    zoom: 6,
    speed: .4,
    // curve: 1.42,
    pitch: 20,
    // bearing: 0,
    easing: function (t) {
      return t * (2 - t);
    },
  });

  // Get popups array

  popupsArr.map(pop => {

    var popup = new mapboxgl.Popup({ closeOnClick: false, closeButton: false, })
      .setLngLat([pop.lng, pop.lat])
      .setHTML(`<a href="${pop.link}">${pop.title}</a>`)
      .addTo(projectsMap);

  })


  const popups = document.querySelectorAll(
    '.jsProjectsMapContainer .mapboxgl-popup'
  );


  // Animate with anime.js
  anime({
    targets: popups,
    opacity: [0, 1],
    delay: anime.stagger(150, { start: 6000 }), // increase delay by 100ms for each elements.
  });

  });
}

